.mainSidebar i{
    display: contents !important;
}

.mainSidebar span {
    margin-left: 10px;
}
.mainSidebar a{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important
}
