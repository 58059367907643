.demo-editor{
    /*background-color: red;*/
    border: 1px solid black;
    padding: 15px;
}
/*.rdw-inline-wrapper .rdw-option-wrapper:nth-of-type(4),
.rdw-inline-wrapper .rdw-option-wrapper:nth-of-type(5),
.rdw-inline-wrapper .rdw-option-wrapper:nth-of-type(6),
.rdw-inline-wrapper .rdw-option-wrapper:nth-of-type(7){
    display: none;
}*/
.rdw-link-modal,
.rdw-embedded-modal{
    height: initial !important;
}

.rdw-dropdown-carettoclose, .rdw-dropdown-carettoopen{
    left: 10% !important;
    right: initial !important;
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content{
    height: initial !important;
}

.rdw-option-wrapper{
    border: 0px !important;
    width: 25px !important;
    height: 25px !important;
}
.rdw-option-active {
    box-shadow: 0px 0px 0px #bfbdbd inset !important;
    background-color: #d0a954 !important;
    /* color: white; */
}
.rdw-option-active img{
    filter: invert(1);
}
