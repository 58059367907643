
.questionImg {
    margin: 15px;
    object-fit: cover;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    border: 1px solid black
  }

  .questionImg i{
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    color: white;
    font-size: 20px !important;
    background: #00000082;
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
.questionImg img{
    width: 100%;
    height: 100%;
}

@media (min-width: 700px) {
    .questionImg {
      width: calc(100% / 5 - 30px);
      height: 200px;
    }
  }

  @media (max-width: 699px) {
    .questionImg {
      width: calc(100% - 30px);
      height: 200px;
    }
  }