
.authPage{
    background-color: #e2e5e8;
   padding-top: 80px;
   padding-bottom: 100px;
   min-height: 100vh;
}
.authBox{
    padding: 40px 20px;
    margin: auto;
    background-color: white;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .authBox{
        width: 92%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .authBox{
        width: 70%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .authBox{
        width: 70%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .authBox{
        width: 50%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .authBox{
        width: 30%;
    }
}
.authBox button{
    width: 100%;
}
.authBox button:hover{
    background-color: #278d78;
}